<template>
<vx-card class="lg:w-10/12 md:w-10/12 select-product">
  <p class="mb-8 text-center">What type of product would you like to create?</p>

  <div class="md:w-8/12 lg:w-10/12 button-container">
    <a
      class="bn-button"

      @click="$router.push({
        name: 'pay-later-product'
    })"
    >
      B2C BNPL
    </a>

    <a
      class="bn-button"
      @click="$router.push({
        name: 'pay-later-product'
    })"
    >
      B2C BNPO
    </a>
  </div>


</vx-card>
</template>

<script>
export default {
  name: "PlanTypeSelect.vue"
}
</script>